.content[data-v-fd56a322] {
  width: 98%;
  margin: 0 auto;
}
.content_data[data-v-fd56a322] {
  background-color: #d8ecff;
}
.each[data-v-fd56a322] {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span[data-v-fd56a322] {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.each a[data-v-fd56a322] {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #141720;
          text-decoration-color: #141720;
}
.icon_bg[data-v-fd56a322] {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.iconfont[data-v-fd56a322] {
  font-size: 18px;
  color: #17a2b8;
}
.content_select[data-v-fd56a322] {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}